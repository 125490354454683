// SORT= TRUE-->ASC / FALSE-->DESC / NULL-->NO SORT

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from "@angular/core";
import _ from "lodash";
import { APIService } from "../api.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { PageLoaderService } from "../page-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { utils, write, WorkBook } from "xlsx";
import { saveAs } from "file-saver";
import $ from "jquery";

@Component({
  selector: "app-admin-panel",
  templateUrl: "./admin-panel.component.html",
  styleUrls: ["./admin-panel.component.scss"],
})
export class AdminPanelComponent implements OnInit {
  sortGid: boolean;
  searchValueGid: string;
  filteredGid: boolean;
  sortName: boolean;
  searchValueName: string;
  filteredName: boolean;
  sortSurname: boolean;
  searchValueSurname: string;
  filteredSurname: boolean;
  sortUID: boolean;
  searchValueUID: string;
  filteredUID: boolean;
  sortMail: boolean;
  searchValueMail: string;
  filteredMail: boolean;
  sortCid: boolean;
  searchValueCid: string;
  filteredCid: boolean;
  sortStatus: boolean;
  searchValueStatus: string;
  filteredStatus: boolean;
  sortDataImport: boolean;
  searchValueDataImport: string;
  filteredDataImport: boolean;
  sortCountry: boolean;
  searchValueCountry: string;
  filteredCountry: boolean;
  sortCompany: boolean;
  searchValueCompany: string;
  filteredCompany: boolean;
  sortDataLogin: boolean;
  searchValueDataLogin: string;
  filteredDataLogin: boolean;
  sortDataMailSend: boolean;
  searchValueDataMailSend: string;
  filteredDataMailSend: boolean;
  sortDataMailVerified: boolean;
  searchValueDataMailVerified: string;
  filteredDataMailVerified: boolean;
  sortDataSetPassword: boolean;
  searchValueDataSetPassword: string;
  filteredDataSetPassword: boolean;
  sortBirthDate: boolean;
  searchValueBirthDate: string;
  filteredBirthDate: boolean;

  errorDataImport: boolean;
  errorDataLogin: boolean;
  errorDataMailSend: boolean;
  errorDataMailVerified: boolean;
  errorDataSetPassword: boolean;
  errorBirthDate: boolean;

  usersList: any;
  count: any;
  page: any;
  pageSize: any;

  statusList: any;
  statusListBackup: any;
  companyList: any;
  companyListBackup: any;
  countriesList: any;
  countriesListBackup: any;

  dataImportList: any;
  dataLoginList: any;
  dataMailSendList: any;
  dataMailVerifiedList: any;
  dataSetPasswordList: any;
  backupDataList = [
    {
      name: "Oggi",
      value: `${moment().format("YYYYMMDD")}`,
      check: false,
    },
    {
      name: "Ultima settimana",
      value: `${moment()
        .startOf("week")
        .add(1, "days")
        .format("YYYYMMDD")}-${moment()
        .endOf("week")
        .add(1, "days")
        .format("YYYYMMDD")}`,
      check: false,
    },
    {
      name: "Ultimo mese",
      value: `${moment().startOf("month").format("YYYYMMDD")}-${moment()
        .endOf("month")
        .format("YYYYMMDD")}`,
      check: false,
    },
    {
      name: "Ultimi 3 mesi",
      value: `${moment()
        .subtract(3, "months")
        .format("YYYYMMDD")}-${moment().format("YYYYMMDD")}`,
      check: false,
    },
    // {
    //   name: "Sempre",
    //   value: "",
    //   check: true,
    // },
  ];

  pageLoader: boolean = false;
  tableLoader: boolean = false;

  queryParams = "?";
  @ViewChild("filterGidTrigger", { static: false })
  filterGidTrigger: any;

  @ViewChild("filterNameTrigger", { static: false }) filterNameTrigger: any;
  @ViewChild("filterSurnameTrigger", { static: false })
  filterSurnameTrigger: any;
  @ViewChild("filterUIDTrigger", { static: false }) filterUIDTrigger: any;
  @ViewChild("filterMailTrigger", { static: false })
  filterMailTrigger: any;
  @ViewChild("filterCidTrigger", { static: false }) filterCidTrigger: any;
  @ViewChild("filterStatusTrigger", { static: false }) filterStatusTrigger: any;
  @ViewChild("filterDataImportTrigger", { static: false })
  filterDataImportTrigger: any;
  @ViewChild("filterCountryTrigger", { static: false })
  filterCountryTrigger: any;
  @ViewChild("filterCompanyTrigger", { static: false })
  filterCompanyTrigger: any;
  @ViewChild("filterDataLoginTrigger", { static: false })
  filterDataLoginTrigger: any;
  @ViewChild("filterDataMailSendTrigger", { static: false })
  filterDataMailSendTrigger: any;
  @ViewChild("filterDataMailVerifiedTrigger", { static: false })
  filterDataMailVerifiedTrigger: any;
  @ViewChild("filterDataSetPasswordTrigger", { static: false })
  filterDataSetPasswordTrigger: any;
  @ViewChild("filterBirthDateTrigger", { static: false })
  filterBirthDateTrigger: any;
  @ViewChild("allStepTrigger", { static: false })
  allStepTrigger: ElementRef;
  @ViewChild("firstStepTrigger", { static: false })
  firstStepTrigger: ElementRef;
  @ViewChild("secondStepTrigger", { static: false })
  secondStepTrigger: ElementRef;
  @ViewChild("thirdStepTrigger", { static: false })
  thirdStepTrigger: ElementRef;
  @ViewChild("fourthStepTrigger", { static: false })
  fourthStepTrigger: ElementRef;
  constructor(
    private renderer: Renderer2,
    private API: APIService,
    private router: Router,
    private load: PageLoaderService,
    private translate: TranslateService
  ) {}

  menuOpen(menu) {
    this["filter" + menu + "Trigger"]._element.nativeElement.classList.add(
      "active"
    );
  }

  menuClose(menu) {
    this["filter" + menu + "Trigger"]._element.nativeElement.classList.remove(
      "active"
    );
  }

  createList(array) {
    var defList = [];
    if (typeof array === "object") {
      array.map((item) => {
        defList.push({
          name: item[Object.keys(item)[0]],
          value: item[Object.keys(item)[1]],
          check: true,
        });
      });
    }
    return defList;
  }

  createUniqList(array) {
    var defList = [];
    array.map((item) => {
      defList.push({ name: item.name, value: item.value, check: item.check });
    });
    return defList;
  }

  formatStatus(status) {
    let userStatus;
    this.statusList.map((item) => {
      if (status === item.value) {
        userStatus = item.name;
      }
    });
    return userStatus;
  }

  formatData(data) {
    let dataFormat = data
      ? moment(data.toString(), "YYYYMMDDHHmm").format("DD/MM/YYYY HH:mm")
      : "";
    return dataFormat;
  }

  formatDataNoHours(data) {
    let dataFormat = data
      ? moment(data.toString(), "YYYYMMDDHHmm").format("DD/MM/YYYY")
      : "";
    return dataFormat;
  }

  formatDataBirth(data) {
    let dataFormat = data
      ? moment(data.toString(), "DD/MM").format("DD/MM")
      : "";
    return dataFormat;
  }

  searchDate(value, field) {
    let name = "searchValue" + field;
    if (this[name].length > 0) {
      this["error" + field] = !moment(this[name], "DD-MM-YYYY").isValid();
    } else {
      this["error" + field] = false;
    }
  }

  async getData(queryParams) {
    this.tableLoader = false;
    let usersList = await this.API.getUserList(queryParams);

    let count = await this.API.getCounter();
    let status = await this.API.getStatusList();
    let company = await this.API.getCompanyList();
    let countries = await this.API.getCountriesList();

    this.count = count.data;
    this.usersList = usersList.data.users;
    if (_.isEmpty(this.statusList)) {
      this.statusList = this.createList(status.data);
      this.statusListBackup = this.createList(status.data);
    }
    if (_.isEmpty(this.companyList)) {
      this.companyList = this.createList(company.data);
      this.companyListBackup = this.createList(company.data);
    }
    if (_.isEmpty(this.countriesList)) {
      this.countriesList = this.createList(countries.data);
      this.countriesListBackup = this.createList(countries.data);
    }
    this["sort" + usersList.data.sort] =
      usersList.data.order === "ASC" ? true : false;

    this.page = usersList.data.page;
    this.pageSize = parseInt(usersList.data.pageSize);

    this.dataImportList = _.isEmpty(this.dataImportList)
      ? this.createUniqList(this.backupDataList)
      : this.dataImportList;
    this.dataLoginList = _.isEmpty(this.dataLoginList)
      ? this.createUniqList(this.backupDataList)
      : this.dataLoginList;
    this.dataMailSendList = _.isEmpty(this.dataMailSendList)
      ? this.createUniqList(this.backupDataList)
      : this.dataMailSendList;
    this.dataMailVerifiedList = _.isEmpty(this.dataMailVerifiedList)
      ? this.createUniqList(this.backupDataList)
      : this.dataMailVerifiedList;
    this.dataSetPasswordList = _.isEmpty(this.dataSetPasswordList)
      ? this.createUniqList(this.backupDataList)
      : this.dataSetPasswordList;

    setTimeout(() => {
      this.tableLoader = true;
    }, 500);
  }

  //loader function
  leaveLoader() {
    if (_.isEmpty(this.translate.store.translations)) {
      setTimeout(() => {
        this.leaveLoader();
      }, 500);
    } else {
      this.pageLoader = true;
      this.load.change(this.pageLoader);
      $(".page-container.desktop").removeClass("desktop");
    }
  }

  async ngOnInit() {
    this.pageLoader = false;
    this.load.change(this.pageLoader);
    let user = JSON.parse(localStorage.getItem("user"));
    let tokenId = localStorage.getItem("tokenId");
    if (!tokenId || tokenId === "") {
      this.router.navigate(["/admin/auth"]);
    } else if (user) {
      await this.API.setUsername(
        user.email,
        `${user.firstName.charAt(0).toUpperCase()}${user.lastName
          .charAt(0)
          .toUpperCase()}`
      );
    }

    this.sortGid = null;
    this.searchValueGid = "";
    this.filteredGid = false;
    this.sortName = null;
    this.searchValueName = "";
    this.filteredName = false;
    this.sortSurname = null;
    this.searchValueSurname = "";
    this.filteredSurname = false;
    this.sortUID = null;
    this.searchValueUID = "";
    this.filteredUID = false;
    this.sortBirthDate = null;
    this.searchValueBirthDate = "";
    this.filteredBirthDate = false;
    this.sortMail = null;
    this.searchValueMail = "";
    this.filteredMail = false;
    this.sortCid = null;
    this.searchValueCid = "";
    this.filteredCid = false;
    this.sortStatus = null;
    this.searchValueStatus = "";
    this.filteredStatus = false;
    this.sortDataImport = null;
    this.searchValueDataImport = "";
    this.filteredDataImport = false;
    this.sortCountry = null;
    this.searchValueCountry = "";
    this.filteredCountry = false;
    this.sortCompany = null;
    this.searchValueCompany = "";
    this.filteredCompany = false;
    this.sortDataLogin = null;
    this.searchValueDataLogin = "";
    this.filteredDataLogin = false;
    this.sortDataMailSend = null;
    this.searchValueDataMailSend = "";
    this.filteredDataMailSend = false;
    this.sortDataMailVerified = null;
    this.searchValueDataMailVerified = "";
    this.filteredDataMailVerified = false;
    this.sortDataSetPassword = null;
    this.searchValueDataSetPassword = "";
    this.filteredDataSetPassword = false;
    this.errorDataImport = false;
    this.errorDataLogin = false;
    this.errorDataMailSend = false;
    this.errorDataMailVerified = false;
    this.errorDataSetPassword = false;
    this.errorBirthDate = false;

    await this.getData("");
    this.applyFilter("");
    this.leaveLoader();
  }

  async createExport() {
    //da sistemare superati 1000000 di users
    let usersList = await this.API.getUserList("?pageSize=1000000");
    const wb: WorkBook = { SheetNames: [], Sheets: {} };
    const ws_name = "Export";
    var wsC: any = utils.json_to_sheet(usersList.data.users);
    wb.SheetNames.push(ws_name);
    wb.Sheets[ws_name] = wsC;

    const wbout = write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });

    function s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    }

    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `Export-theIdHub-${moment().format("DD-MM-YYYY")}.xlsx`
    );
  }

  sortFilterMenu(menu) {
    switch (menu) {
      case "Gid":
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortDataSetPassword = null;
        this.sortBirthDate = null;
        break;
      case "Name":
        this.sortGid = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortDataSetPassword = null;
        this.sortBirthDate = null;
        break;
      case "Surname":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortDataSetPassword = null;
        this.sortBirthDate = null;
        break;
      case "BirthDate":
        this.sortGid = null;
        this.sortName = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortDataSetPassword = null;
        break;
      case "UID":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortDataSetPassword = null;
        this.sortBirthDate = null;
        break;
      case "Mail":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortDataSetPassword = null;
        this.sortBirthDate = null;
        break;
      case "Cid":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortBirthDate = null;
        this.sortDataSetPassword = null;
        break;
      case "Status":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortBirthDate = null;
        this.sortDataSetPassword = null;
        break;
      case "DataImport":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortBirthDate = null;
        this.sortDataSetPassword = null;
        break;
      case "Country":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortBirthDate = null;
        this.sortDataSetPassword = null;
        break;
      case "Company":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortBirthDate = null;
        this.sortDataSetPassword = null;
        break;
      case "DataLogin":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataMailSend = null;
        this.sortDataMailVerified = null;
        this.sortBirthDate = null;
        this.sortDataSetPassword = null;
        break;
      case "DataMailSend":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailVerified = null;
        this.sortBirthDate = null;
        this.sortDataSetPassword = null;
        break;
      case "DataMailVerified":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortBirthDate = null;
        this.sortDataSetPassword = null;
        break;
      case "DataSetPassword":
        this.sortGid = null;
        this.sortName = null;
        this.sortSurname = null;
        this.sortUID = null;
        this.sortMail = null;
        this.sortCid = null;
        this.sortStatus = null;
        this.sortDataImport = null;
        this.sortCountry = null;
        this.sortCompany = null;
        this.sortDataLogin = null;
        this.sortDataMailSend = null;
        this.sortBirthDate = null;
        this.sortDataMailVerified = null;
    }
    if (this["sort" + menu] !== null) {
      this["sort" + menu] = !this["sort" + menu];
    } else {
      this["sort" + menu] = true;
    }
  }

  changeCheckbox(startArray, finalArray, name) {
    var arrayList = [];
    startArray.map((item) => {
      arrayList.push(
        name === item.name
          ? { name: item.name, value: item.value, check: !item.check }
          : { ...item }
      );
    });
    this[finalArray] = arrayList;
  }

  changeUniqCheckbox(startArray, finalArray, name) {
    var arrayList = [];
    startArray.map((item) => {
      arrayList.push(
        name === item.name
          ? { name: item.name, value: item.value, check: !item.check }
          : { name: item.name, value: item.value, check: false }
      );
    });
    this[finalArray] = arrayList;
  }

  filterCheckbox(startArray, finalArray, text) {
    if (this[text].trim().length === 0) {
      this[finalArray] = this[finalArray + "Backup"];
    } else {
      let re = new RegExp(
        "(?:^|\\s)(" + this[text].toLowerCase().trim() + "w*)",
        "i"
      );
      var arrayList = startArray.filter((item) => {
        let name = item.name.toLowerCase();
        return re.test(name);
      });
      this[finalArray] = arrayList;
    }
  }

  applyFilterStep(step) {
    switch (step) {
      case 10:
        this.allStepTrigger.nativeElement.classList.remove("active");
        this.firstStepTrigger.nativeElement.classList.add("active");
        this.secondStepTrigger.nativeElement.classList.remove("active");
        this.thirdStepTrigger.nativeElement.classList.remove("active");
        this.fourthStepTrigger.nativeElement.classList.remove("active");
        break;
      case 20:
        this.allStepTrigger.nativeElement.classList.remove("active");
        this.firstStepTrigger.nativeElement.classList.remove("active");
        this.secondStepTrigger.nativeElement.classList.add("active");
        this.thirdStepTrigger.nativeElement.classList.remove("active");
        this.fourthStepTrigger.nativeElement.classList.remove("active");
        break;
      case 30:
        this.allStepTrigger.nativeElement.classList.remove("active");
        this.firstStepTrigger.nativeElement.classList.remove("active");
        this.secondStepTrigger.nativeElement.classList.remove("active");
        this.thirdStepTrigger.nativeElement.classList.add("active");
        this.fourthStepTrigger.nativeElement.classList.remove("active");
        break;
      case 40:
        this.allStepTrigger.nativeElement.classList.remove("active");
        this.firstStepTrigger.nativeElement.classList.remove("active");
        this.secondStepTrigger.nativeElement.classList.remove("active");
        this.thirdStepTrigger.nativeElement.classList.remove("active");
        this.fourthStepTrigger.nativeElement.classList.add("active");
        break;
      default:
        this.allStepTrigger.nativeElement.classList.add("active");
        this.firstStepTrigger.nativeElement.classList.remove("active");
        this.secondStepTrigger.nativeElement.classList.remove("active");
        this.thirdStepTrigger.nativeElement.classList.remove("active");
        this.fourthStepTrigger.nativeElement.classList.remove("active");
        break;
    }

    if (step) {
      let arrayList = [];
      this.statusListBackup.map((item) => {
        arrayList.push(
          step === item.value
            ? { name: item.name, value: item.value, check: true }
            : { name: item.name, value: item.value, check: false }
        );
      });
      this.statusList = arrayList;
    } else {
      this.statusList = this.statusListBackup;
    }
    this.applyFilter("");
  }

  resetFilter(menu) {
    this["filter" + menu + "Trigger"].closeMenu();
    switch (menu) {
      case "Gid":
        this.sortGid = null;
        this.searchValueGid = "";
        break;
      case "Name":
        this.sortName = null;
        this.searchValueName = "";
        break;
      case "Surname":
        this.sortSurname = null;
        this.searchValueSurname = "";
        break;
      case "BirthDate":
        this.sortBirthDate = null;
        this.searchValueBirthDate = "";
        break;
      case "UID":
        this.sortUID = null;
        this.searchValueUID = "";
        break;
      case "Mail":
        this.sortMail = null;
        this.searchValueMail = "";
        break;
      case "Cid":
        this.sortCid = null;
        this.searchValueCid = "";
        break;
      case "Status":
        this.sortStatus = null;
        this.searchValueStatus = "";
        this.statusList = this.statusListBackup;
        break;
      case "DataImport":
        this.sortDataImport = null;
        this.searchValueDataImport = "";
        this.dataImportList = this.backupDataList;
        break;
      case "Country":
        this.sortCountry = null;
        this.searchValueCountry = "";
        this.countriesList = this.countriesListBackup;
        break;
      case "Company":
        this.sortCompany = null;
        this.searchValueCompany = "";
        this.companyList = this.companyListBackup;
        break;
      case "DataLogin":
        this.sortDataLogin = null;
        this.searchValueDataLogin = "";
        this.dataLoginList = this.backupDataList;
        break;
      case "DataMailSend":
        this.sortDataMailSend = null;
        this.searchValueDataMailSend = "";
        this.dataMailSendList = this.backupDataList;
        break;
      case "DataMailVerified":
        this.sortDataMailVerified = null;
        this.searchValueDataMailVerified = "";
        this.dataMailVerifiedList = this.backupDataList;
        break;
      case "DataSetPassword":
        this.sortDataSetPassword = null;
        this.searchValueDataSetPassword = "";
        this.dataSetPasswordList = this.backupDataList;
    }
    this.applyFilter("");
  }

  clearAll() {
    this.sortGid = null;
    this.searchValueGid = "";
    this.sortName = null;
    this.searchValueName = "";
    this.sortSurname = null;
    this.searchValueSurname = "";
    this.sortBirthDate = null;
    this.searchValueBirthDate = "";
    this.sortUID = null;
    this.searchValueUID = "";
    this.sortMail = null;
    this.searchValueMail = "";
    this.sortCid = null;
    this.searchValueCid = "";
    this.sortStatus = null;
    this.searchValueStatus = "";
    this.statusList = this.statusListBackup;
    this.companyList = this.companyListBackup;
    this.countriesList = this.countriesListBackup;
    this.sortDataImport = null;
    this.searchValueDataImport = "";
    this.dataImportList = this.backupDataList;
    this.sortCountry = null;
    this.searchValueCountry = "";
    this.sortCompany = null;
    this.searchValueCompany = "";
    this.sortDataLogin = null;
    this.searchValueDataLogin = "";
    this.dataLoginList = this.backupDataList;
    this.sortDataMailSend = null;
    this.searchValueDataMailSend = "";
    this.dataMailSendList = this.backupDataList;
    this.sortDataMailVerified = null;
    this.searchValueDataMailVerified = "";
    this.dataMailVerifiedList = this.backupDataList;
    this.sortDataSetPassword = null;
    this.searchValueDataSetPassword = "";
    this.dataSetPasswordList = this.backupDataList;
    this.applyFilter("");
  }

  clearInput(menu) {
    switch (menu) {
      case "Gid":
        this.searchValueGid = "";
        break;
      case "Name":
        this.searchValueName = "";
        break;
      case "Surname":
        this.searchValueSurname = "";
        break;
      case "BirthDate":
        this.searchValueBirthDate = "";
        break;
      case "UID":
        this.searchValueUID = "";
        break;
      case "Mail":
        this.searchValueMail = "";
        break;
      case "Cid":
        this.searchValueCid = "";
        break;
      case "Status":
        this.searchValueStatus = "";
        this.statusList = this.statusListBackup;
        break;
      case "DataImport":
        this.searchValueDataImport = "";
        break;
      case "Country":
        this.searchValueCountry = "";
        this.countriesList = this.countriesListBackup;
        break;
      case "Company":
        this.searchValueCompany = "";
        this.companyList = this.companyListBackup;
        break;
      case "DataLogin":
        this.searchValueDataLogin = "";
        break;
      case "DataMailSend":
        this.searchValueDataMailSend = "";
        break;
      case "DataMailVerified":
        this.searchValueDataMailVerified = "";
        break;
      case "DataSetPassword":
        this.searchValueDataSetPassword = "";
    }
  }

  async applyFilter(menu) {
    let queryParams = "?";

    //creazione dei queryParams per il sort
    if (this.sortGid !== null) {
      queryParams =
        queryParams + `sort=GID&order=${this.sortGid ? "ASC" : "DESC"}`;
    } else if (this.sortName !== null) {
      queryParams =
        queryParams + `sort=name&order=${this.sortName ? "ASC" : "DESC"}`;
    } else if (this.sortSurname !== null) {
      queryParams =
        queryParams + `sort=surname&order=${this.sortSurname ? "ASC" : "DESC"}`;
    } else if (this.sortUID !== null) {
      queryParams =
        queryParams + `sort=UID&order=${this.sortUID ? "ASC" : "DESC"}`;
    } else if (this.sortMail !== null) {
      queryParams =
        queryParams + `sort=maill&order=${this.sortMail ? "ASC" : "DESC"}`;
    } else if (this.sortCid !== null) {
      queryParams =
        queryParams + `sort=Cid&order=${this.sortCid ? "ASC" : "DESC"}`;
    } else if (this.sortStatus !== null) {
      queryParams =
        queryParams + `sort=status&order=${this.sortStatus ? "ASC" : "DESC"}`;
    } else if (this.sortDataImport !== null) {
      queryParams =
        queryParams +
        `sort=dataImport&order=${this.sortDataImport ? "ASC" : "DESC"}`;
    } else if (this.sortCountry !== null) {
      queryParams =
        queryParams + `sort=Country&order=${this.sortCountry ? "ASC" : "DESC"}`;
    } else if (this.sortCompany !== null) {
      queryParams =
        queryParams + `sort=company&order=${this.sortCompany ? "ASC" : "DESC"}`;
    } else if (this.sortDataLogin !== null) {
      queryParams =
        queryParams +
        `sort=dataLogin&order=${this.sortDataLogin ? "ASC" : "DESC"}`;
    } else if (this.sortDataMailSend !== null) {
      queryParams =
        queryParams +
        `sort=dataMailSend&order=${this.sortDataMailSend ? "ASC" : "DESC"}`;
    } else if (this.sortDataMailVerified !== null) {
      queryParams =
        queryParams +
        `sort=dataMailVerified&order=${
          this.sortDataMailVerified ? "ASC" : "DESC"
        }`;
    } else if (this.sortDataSetPassword !== null) {
      queryParams =
        queryParams +
        `sort=dataMailSetPassword&order=${
          this.sortDataSetPassword ? "ASC" : "DESC"
        }`;
    }

    // creazione dei queryParams per gli input search
    if (this.searchValueGid.trim() !== "") {
      queryParams = queryParams + `&gid=${this.searchValueGid}`;
      this.filteredGid = true;
    } else {
      this.filteredGid = false;
    }
    if (this.searchValueName.trim() !== "") {
      queryParams = queryParams + `&name=${this.searchValueName}`;
      this.filteredName = true;
    } else {
      this.filteredName = false;
    }
    if (this.searchValueSurname.trim() !== "") {
      queryParams = queryParams + `&surname=${this.searchValueSurname}`;
      this.filteredSurname = true;
    } else {
      this.filteredSurname = false;
    }
    if (this.searchValueUID.trim() !== "") {
      queryParams = queryParams + `&UID=${this.searchValueUID}`;
      this.filteredUID = true;
    } else {
      this.filteredUID = false;
    }
    if (this.searchValueMail.trim() !== "") {
      queryParams = queryParams + `&mail=${this.searchValueMail}`;
      this.filteredMail = true;
    } else {
      this.filteredMail = false;
    }
    if (this.searchValueCid.trim() !== "") {
      queryParams = queryParams + `&cid=${this.searchValueCid}`;
      this.filteredCid = true;
    } else {
      this.filteredCid = false;
    }

    if (
      this.searchValueBirthDate.trim() !== "" &&
      this.errorBirthDate === false
    ) {
      queryParams =
        queryParams +
        `&birthDate=${moment(this.searchValueBirthDate, "DDMMYYYY").format(
          "YYYYMMDD"
        )}`;
      this.filteredBirthDate = true;
    } else {
      this.filteredBirthDate = false;
    }

    if (
      this.searchValueDataImport.trim() !== "" &&
      this.errorDataImport === false
    ) {
      queryParams =
        queryParams +
        `&dataImport=${moment(this.searchValueDataImport, "DDMMYYYY").format(
          "YYYYMMDD"
        )}`;
      this.filteredDataImport = true;
      this.dataImportList = this.backupDataList;
    } else {
      this.filteredDataImport = false;
    }

    if (
      this.searchValueDataLogin.trim() !== "" &&
      this.errorDataLogin === false
    ) {
      queryParams =
        queryParams +
        `&dataLogin=${moment(this.searchValueDataLogin, "DDMMYYYY").format(
          "YYYYMMDD"
        )}`;
      this.filteredDataLogin = true;
      this.dataLoginList = this.backupDataList;
    } else {
      this.filteredDataLogin = false;
    }

    if (
      this.searchValueDataMailSend.trim() !== "" &&
      this.errorDataMailSend === false
    ) {
      queryParams =
        queryParams +
        `&dataMailSend=${moment(
          this.searchValueDataMailSend,
          "DDMMYYYY"
        ).format("YYYYMMDD")}`;
      this.filteredDataMailSend = true;
      this.dataMailSendList = this.backupDataList;
      this.dataMailSendList[4].check = false;
    } else {
      this.filteredDataMailSend = false;
    }

    if (
      this.searchValueDataMailVerified.trim() !== "" &&
      this.errorDataMailVerified === false
    ) {
      queryParams =
        queryParams +
        `&dataMailVerified=${moment(
          this.searchValueDataMailVerified,
          "DDMMYYYY"
        ).format("YYYYMMDD")}`;
      this.filteredDataMailVerified = true;
      this.dataMailVerifiedList = this.backupDataList;
    } else {
      this.filteredDataMailVerified = false;
    }

    if (
      this.searchValueDataSetPassword.trim() !== "" &&
      this.errorDataSetPassword === false
    ) {
      queryParams =
        queryParams +
        `&dataMailSetPassword=${moment(
          this.searchValueDataSetPassword,
          "DDMMYYYY"
        ).format("YYYYMMDD")}`;
      this.filteredDataSetPassword = true;
      this.dataSetPasswordList = this.backupDataList;
    } else {
      this.filteredDataSetPassword = false;
    }

    //creazione del queryParams per la lista StatusList
    let statusListFilter = this.statusList.filter((item) => {
      return item.check;
    });
    let statusString = "&status=";
    if (statusListFilter.length !== this.statusList.length) {
      statusString = `&status=${this.statusList
        .filter((item) => {
          return item.check;
        })
        .map((i) => {
          return i.value;
        })
        .join(",")}`;
    }
    if (statusString !== "&status=") {
      queryParams = queryParams + statusString;
      this.filteredStatus = true;
    } else {
      this.filteredStatus = false;
    }

    //creazione del queryParams per la lista companyList
    let companyListFilter = this.companyList.filter((item) => {
      return item.check;
    });
    let companyString = "&company=";
    if (companyListFilter.length !== this.companyList.length) {
      companyString = `&company=${this.companyList
        .filter((item) => {
          return !item.check;
        })
        .map((i) => {
          return i.value;
        })
        .join(",")}`;
    }
    if (companyString !== "&company=") {
      queryParams = queryParams + companyString;
      this.filteredCompany = true;
    } else {
      this.filteredCompany = false;
    }

    //creazione del queryParams per la lista countriesList
    let countriesListFilter = this.countriesList.filter((item) => {
      return item.check;
    });
    let countriesString = "&countries=";
    if (countriesListFilter.length !== this.countriesList.length) {
      countriesString = `&countries=${this.countriesList
        .filter((item) => {
          return item.check;
        })
        .map((i) => {
          return i.value;
        })
        .join(",")}`;
    }
    if (countriesString !== "&countries=") {
      queryParams = queryParams + countriesString;
      this.filteredCountry = true;
    } else {
      this.filteredCountry = false;
    }

    //creazione del queryParams per la lista DataImportList
    let dataImportString = `&dataImport=${this.dataImportList
      .filter((item) => {
        return item.check;
      })
      .map((i) => {
        return i.value;
      })
      .join(",")}`;

    if (dataImportString !== "&dataImport=") {
      queryParams = queryParams + dataImportString;
      this.filteredDataImport = true;
    }

    //creazione del queryParams per la lista DataLoginList
    let dataLoginString = `&dataLogin=${this.dataLoginList
      .filter((item) => {
        return item.check;
      })
      .map((i) => {
        return i.value;
      })
      .join(",")}`;

    if (dataLoginString !== "&dataLogin=") {
      queryParams = queryParams + dataLoginString;
      this.filteredDataLogin = true;
    }

    //creazione del queryParams per la lista DataMailSendList
    let dataMailSendString = `&dataMailSend=${this.dataMailSendList
      .filter((item) => {
        return item.check;
      })
      .map((i) => {
        return i.value;
      })
      .join(",")}`;

    if (dataMailSendString !== "&dataMailSend=") {
      queryParams = queryParams + dataMailSendString;
      this.filteredDataMailSend = true;
    }

    //creazione del queryParams per la lista DataMailVerifiedList
    let dataMailVerifiedString = `&dataMailVerified=${this.dataMailVerifiedList
      .filter((item) => {
        return item.check;
      })
      .map((i) => {
        return i.value;
      })
      .join(",")}`;

    if (dataMailVerifiedString !== "&dataMailVerified=") {
      queryParams = queryParams + dataMailVerifiedString;
      this.filteredDataMailVerified = true;
    }

    //creazione del queryParams per la lista DataSetPasswordList
    let dataSetPasswordString = `&dataMailSetPassword=${this.dataSetPasswordList
      .filter((item) => {
        return item.check;
      })
      .map((i) => {
        return i.value;
      })
      .join(",")}`;

    if (dataSetPasswordString !== "&dataMailSetPassword=") {
      queryParams = queryParams + dataSetPasswordString;
      this.filteredDataSetPassword = true;
    }

    this.getData(queryParams);

    if (menu !== "") {
      this["filter" + menu + "Trigger"].closeMenu();
    }

    this.queryParams = queryParams;
  }

  nextPage() {
    let queryParams =
      this.queryParams === "?" ? this.queryParams + "" : this.queryParams + "&";
    queryParams = `${queryParams}page=${parseInt(this.page) + 1}&pageSize=${
      this.pageSize
    }`;
    this.page = parseInt(this.page) + 1;
    this.getData(queryParams);
  }

  prevPage() {
    let queryParams =
      this.queryParams === "?" ? this.queryParams + "" : this.queryParams + "&";
    queryParams = `${queryParams}page=${
      parseInt(this.page) > 1 ? parseInt(this.page) - 1 : parseInt(this.page)
    }&pageSize=${this.pageSize}`;
    this.page =
      parseInt(this.page) > 1 ? parseInt(this.page) - 1 : parseInt(this.page);
    this.getData(queryParams);
  }

  userQuantity(pageSize) {
    this.pageSize = pageSize;
    let queryParams =
      this.queryParams === "?" ? this.queryParams + "" : this.queryParams + "&";
    queryParams = `${queryParams}page=${this.page}&pageSize=${pageSize}`;
    this.getData(queryParams);
  }
}
